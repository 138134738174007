import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import TGModal from "../../../tg-modal";
import TGButtonVariants from "../../../tg-button-variants";
import TGIcon from "../../../tg-icon";

import styles from "./account-locked.module.scss";
import useScreenSize from "../../../../utils/hook/useScreenSize";

interface IProps {
  hidePanel: () => void;
  componentName?: string;
}
const AccountLocked = (props: IProps) => {
  const [show, setShow] = useState(true);
  const { i18n } = useTranslation();
  const handleOpen = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    props.hidePanel();
  };
  const isMobileView: boolean = useScreenSize().device === "mobile";

  useEffect(() => {
    if (show) {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    } else {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    }
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, [show]);

  const handleNavigation = () => {
    window.location.href = "/unlockaccount";
    props.hidePanel();
  };

  const handleUnSubscribeNavigation = () => {
    window.location.href = "/";
    props.hidePanel();
  };

  return (
    <>
      <TGModal
        show={show}
        handleClose={handleClose}
        buttonLabel="Ok"
        customModalClass={styles.customModalClass}
        customOffCanvasClass={styles.customOffCanvasClass}
        centered={true}
        backdrop="static"
      >
        <div className={styles.tcModalFlex}>
          <div className={styles.tcModal}>
            <TGIcon
              fillColor={"none"}
              icon={
                props.componentName == "unsubscribenewsLetter" ||
                props.componentName == "subscribenewsLetter"
                  ? "unsubscribe-icon"
                  : "account-locked-icon"
              }
            />
            <div className={styles.modalContent}>
              <span className={styles.modalHeading}>
                {props.componentName == "unsubscribenewsLetter" ||
                props.componentName == "subscribenewsLetter"
                  ? "Congratulations"
                  : t("unlock_account")}
              </span>
              <div className={styles.modalDescription}>
                {props.componentName == "unsubscribenewsLetter" ||
                props.componentName == "subscribenewsLetter"
                  ? "You are now subscribed to receive our latest updates, exclusive offers, and exciting news straight to your inbox, welcome aboard!"
                  : t("unlock_account_description")}
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <TGButtonVariants
              label={
                props.componentName == "unsubscribenewsLetter" ||
                props.componentName == "subscribenewsLetter"
                  ? "Go to Home"
                  : t("label_unlock_account")
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
              bgColor="#381B6B"
              bgColorHover="#3D2D5E"
              padding="8px 24px"
              textColor="#FFFFFF"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              height="50px"
              width={
                (props.componentName == "unsubscribenewsLetter" ||
                  props.componentName == "subscribenewsLetter") &&
                !isMobileView
                  ? "170px"
                  : ""
              }
              onClick={
                props.componentName == "unsubscribenewsLetter" ||
                props.componentName == "subscribenewsLetter"
                  ? handleUnSubscribeNavigation
                  : handleNavigation
              }
            />
          </div>
        </div>
      </TGModal>
    </>
  );
};

export default AccountLocked;
