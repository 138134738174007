import React, { useEffect, useState } from "react";
import { Modal, Form, Stack, Spinner } from "react-bootstrap";
import styles from "./login-otp.module.scss";
import TGInput from "../../tg-input";
import TGButtonVariants from "../../tg-button-variants";
import TGIcon from "../../tg-icon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendOTPPending, resetOTPData } from "../../../OSCI/src/slice/otpSlice";
import {
  resendOTPClear,
  resendOTPPending,
} from "../../../OSCI/src/slice/resendSlice";
import {
  crmResendOTPClear,
  crmResendOTPPending,
} from "../../../OSCI/src/slice/crmResendOTPSlice";
import { OTPFormData, OTPDetails } from "../../../OSCI/src/models/models";
import { RootState } from "../../../OSCI/src/slice/RootReducer";
import { Root } from "react-dom/client";
import TGOTPInput from "../../../shared/tg-otp-input";
import {
  sendCRMOTPPending,
  resetCRMOTPData,
} from "../../../OSCI/src/slice/crmOTPSlice";
import {
  sendChangePasswordOTPPending,
  resetChangePasswordOTPData,
} from "../../../OSCI/src/slice/changePasswordOTPSlice";
import { changePasswordResendOTPPending } from "../../../OSCI/src/slice/changePasswordOTPResendSlice";
import {resendNomineeOTPRequest, submitNomineeOTPRequest} from "../../../OSCI/src/slice/addNomineeSlice"

interface Props {
  onHideModal: () => void;
  updateUserName: (name: string) => void;
  isHidden: (value: boolean) => void;
  showFrequentCodePopUp?: any;
  showSessionExpirePopUp?: any;
  handleSuccess?: () => {};
  keyLoggedInProfile?: string;
  isChangePassword?: boolean;
  page?:string;
  data?: {}
}

const LoginOTP = (props: Props) => {
  const [resendTimer, setResend] = useState(59);
  const [otpKey, setOtpKey] = useState("");
  const [otp, setOtp] = useState("");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<Partial<OTPFormData>>({});
  const [error, setError] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [resetTimer, setResetTimer] = useState(0);
  const [resendLimitExceeded, setResendLimitExceeded] = useState(false)
  const [showPopup, setShowPopup]= useState(false)

  const response: any = useSelector(
    (state: RootState) => state?.osciReducer?.otpData
  );
  const maskedMail: any = sessionStorage.getItem("mailId");

  const resendOTPResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.resendOTPData
  );
  let name: string = response?.otpDetails?.data?.fullName;

  const errorMessage: string = useSelector(
    (state: RootState) => state?.osciReducer?.loginData.error
  );
  const errorInvalidOTP: any = useSelector(
    (state: RootState) => state?.osciReducer?.otpData.error
  );
  const errorInResendOTP: any = useSelector(
    (state: RootState) => state?.osciReducer?.resendOTPData.error
  );

  const crmOTPResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.crmotpData
  );

  const crmLoginResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.crmLoginData
  );

  const changePasswordOTPResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.changePasswordotpData
  );

  const changePasswordResendOTPResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.changePasswordResend
  );

  const crmResendOTPResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.crmResendOTPData
  );

  const updateSessionData = () => {
    sessionStorage.setItem("FullName", response?.otpDetails?.data?.fullName);
    if (
      response?.otpDetails?.data?.fullName.includes(".") ||
      response?.otpDetails?.data?.fullName.split(" ").length >= 3
    ) {
      sessionStorage.setItem(
        "Salutation",
        response?.otpDetails?.data?.fullName.split(" ")[0]
      );
      sessionStorage.setItem(
        "FirstName",
        response?.otpDetails?.data?.fullName.split(" ")[1]
      );
      sessionStorage.setItem(
        "LastName",
        response?.otpDetails?.data?.fullName.split("  ")[1]
      );
    } else {
      sessionStorage.setItem(
        "FirstName",
        response?.otpDetails?.data?.fullName.split(" ")[0]
      );
      sessionStorage.setItem(
        "LastName",
        response?.otpDetails?.data?.fullName.split("  ")[1]
      );
    }

    sessionStorage.removeItem("temptoken");
    sessionStorage.removeItem("refNumber");
  };
  useEffect(() => {
    return () => {
      dispatch(resetOTPData());
      dispatch(resetChangePasswordOTPData());
      setError("");
    };
  }, []);

  useEffect(() => {
    if (name?.length > 0) {
      props.updateUserName(name);
    }
  }, [name]);

  //Timer
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => setResend(resendTimer - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);

  useEffect(() => {
    if (crmLoginResponse?.codeStatus === "success") {
      setTimeout(() => {
        setError("");
      }, 0);
    }
  }, [crmLoginResponse, crmLoginResponse?.codeStatus]);

  useEffect(() => {
    if (crmResendOTPResponse?.codeStatus === "success") {
      setTimeout(() => {
        setError("");
      }, 0);
    }
  }, [crmResendOTPResponse, crmResendOTPResponse?.codeStatus]);

  // CRMOTP api success and hidden sign-in popup model
  useEffect(() => {
    if (
      crmOTPResponse?.hasData &&
      !crmOTPResponse.isLoading &&
      crmOTPResponse.codeStatus === "success"
    ) {
      props.onHideModal();
      sessionStorage.setItem(
        "firstName",
        crmOTPResponse?.crmOTP?.profileDetail?.firstName
      );
      sessionStorage.setItem(
        "lastName",
        crmOTPResponse?.crmOTP?.profileDetail?.lastName
      );
    } else if (
      !crmOTPResponse.isLoading &&
      crmOTPResponse.codeStatus === "failure"
    ) {
      if (
        (crmOTPResponse?.error?.response?.data?.Code?.toString() === "400" &&
          crmOTPResponse?.error?.response?.data?.Message?.toString() ===
            "Invalid OTP") ||
        crmOTPResponse?.error?.response?.data?.Message?.toString() ===
          "Invalid user"
      ) {
        setError(t("label_incorrect_OTP"));
      } else if (
        crmOTPResponse?.error?.response?.data?.Status?.toString() === "400" &&
        crmOTPResponse?.error?.response?.data?.Code?.toString() === "014"
      ) {
        setError(t("label_incorrect_OTP"));
      } else if (
        crmOTPResponse?.error?.response?.data?.Code?.toString() === "400" &&
        crmOTPResponse?.error?.response?.data?.Message?.toString() ===
          "Invalid Ref No OR otp expiered"
      ) {
        setError(t("label_session_expired"));
      } else {
        setError(t("label_something_went_wrong"));
      }
    } else if (
      crmResendOTPResponse?.error?.response?.data?.Status?.toString() ===
        "400" &&
      crmResendOTPResponse?.error?.response?.data?.Code === "015"
    ) {
      setError(crmResendOTPResponse?.error?.response?.data?.Message);
    }
  }, [
    crmOTPResponse?.hasData,
    crmOTPResponse?.isLoading,
    crmResendOTPResponse,
  ]);

  useEffect(() => {
    if (
      response?.hasData &&
      !response.isLoading &&
      Object.keys(response?.otpDetails)?.length > 0 &&
      response?.otpDetails?.success
    ) {
      updateSessionData();
      props.onHideModal();
      props.handleSuccess && props.handleSuccess();
    } else if (
      !response.hasData &&
      Object.keys(response?.otpDetails)?.length === 0 &&
      response.codeStatus === "failure"
    ) {
      if (response?.error?.response?.data?.code?.toString() === "498") {
        props.showSessionExpirePopUp && props.showSessionExpirePopUp();
      } else if (response?.error?.response?.data?.code?.toString() === "1007") {
        props.showFrequentCodePopUp(true);
      } else {
        setError(t("label_something_went_wrong"));
      }
    }
    if (
      !response?.otpDetails?.otp &&
      !response?.otpDetails?.success &&
      (response?.error?.response?.data?.code?.toString() === "1009" ||
        response?.error?.response?.data?.code?.toString() === "1008")
    ) {
      setError(t("label_incorrect_OTP"));
    }
  }, [response?.hasData, response?.isLoading]);

  useEffect(() => {
    if (
      changePasswordOTPResponse?.hasData &&
      !changePasswordOTPResponse?.isLoading &&
      Object.keys(changePasswordOTPResponse?.changePasswordOTP)?.length > 0 &&
      !!changePasswordOTPResponse?.changePasswordOTP?.otp
    ) {
      updateSessionData();
      props.onHideModal();
      props.handleSuccess && props.handleSuccess();
    } else if (
      !changePasswordOTPResponse?.hasData &&
      Object.keys(changePasswordOTPResponse?.changePasswordOTP)?.length === 0 &&
      changePasswordOTPResponse?.codeStatus === "failure"
    ) {
      if (
        changePasswordOTPResponse?.error?.response?.data?.code?.toString() ===
        "498"
      ) {
        props.showSessionExpirePopUp && props.showSessionExpirePopUp();
      } else if (
        changePasswordOTPResponse?.error?.response?.data?.code?.toString() ===
        "015"
      ) {
        props.showFrequentCodePopUp(true);
      } else {
        setError(t("label_something_went_wrong"));
      }
    }
    if (
      !changePasswordOTPResponse?.changePasswordOTP?.otp &&
      !changePasswordOTPResponse?.changePasswordOTP?.success &&
      (changePasswordOTPResponse?.error?.response?.data?.code?.toString() ===
        "014" ||
        changePasswordOTPResponse?.error?.response?.data?.code?.toString() ===
          "015")
    ) {
      setError(t("label_incorrect_OTP"));
    }
  }, [
    changePasswordOTPResponse,
    changePasswordOTPResponse?.hasData,
    changePasswordOTPResponse?.isLoading,
    props,
  ]);

  useEffect(() => {
    if (
      resendOTPResponse?.hasData &&
      !resendOTPResponse.isLoading &&
      Object.keys(resendOTPResponse?.resendOTPDetails)?.length > 0
    ) {
      sessionStorage.setItem(
        "refNumber",
        resendOTPResponse?.resendOTPDetails?.otpRefKey
      );
      if (
        resendOTPResponse?.error?.response?.data?.code?.toString() === "498"
      ) {
        props.showSessionExpirePopUp && props.showSessionExpirePopUp();
      } else if (
        resendOTPResponse?.error?.response?.data?.code?.toString() === "1007"
      ) {
        props.showFrequentCodePopUp(true);
      } else {
        setError(t("label_something_went_wrong"));
      }
    } else if (
      !resendOTPResponse.hasData &&
      Object.keys(resendOTPResponse?.resendOTPDetails)?.length === 0 &&
      resendOTPResponse.codeStatus === "failure"
    ) {
      if (
        resendOTPResponse?.error?.response?.data?.code?.toString() === "498"
      ) {
        props.showSessionExpirePopUp && props.showSessionExpirePopUp();
      } else if (
        resendOTPResponse?.error?.response?.data?.code?.toString() === "1007"
      ) {
        props.showFrequentCodePopUp(true);
      } else {
        setError(t("label_something_went_wrong"));
      }
    }
  }, [resendOTPResponse?.hasData, resendOTPResponse?.isLoading]);

  useEffect(() => {
    if (
      changePasswordResendOTPResponse?.hasData &&
      !changePasswordResendOTPResponse.isLoading &&
      Object.keys(
        changePasswordResendOTPResponse?.changePasswordResendOTPDetails
      )?.length > 0
    ) {
      sessionStorage.setItem(
        "refNumber",
        changePasswordResendOTPResponse?.changePasswordResendOTPDetails?.data
          ?.otpRefKey
      );
      if (
        changePasswordResendOTPResponse?.error?.response?.data?.code?.toString() ===
        "015"
      ) {
        props.showSessionExpirePopUp && props.showSessionExpirePopUp();
      } else if (
        changePasswordResendOTPResponse?.error?.response?.data?.code?.toString() ===
        "1007"
      ) {
        props.showFrequentCodePopUp(true);
      } else {
        setError(t("label_something_went_wrong"));
      }
    } else if (
      !changePasswordResendOTPResponse.hasData &&
      Object.keys(
        changePasswordResendOTPResponse?.changePasswordResendOTPDetails
      )?.length === 0 &&
      changePasswordResendOTPResponse.codeStatus === "failure"
    ) {
      if (
        changePasswordResendOTPResponse?.error?.response?.data?.code?.toString() ===
        "498"
      ) {
        props.showSessionExpirePopUp && props.showSessionExpirePopUp();
      } else if (
        changePasswordResendOTPResponse?.error?.response?.data?.code?.toString() ===
        "E5051"
      ) {
        props.showFrequentCodePopUp(true);
      } else {
        setError(t("label_something_went_wrong"));
      }
    }
  }, [
    props,
    changePasswordResendOTPResponse.codeStatus,
    changePasswordResendOTPResponse?.error?.response?.data?.code,
    changePasswordResendOTPResponse.hasData,
    changePasswordResendOTPResponse.isLoading,
    changePasswordResendOTPResponse?.changePasswordResendOTPDetails,
    t,
  ]);
//Need to handle the Error in future
  // useEffect(() => {
  //   if (
  //     paidNomineeOTPResponse?.hasData &&
  //     !paidNomineeOTPResponse?.isLoading &&
  //     Object.keys(paidNomineeOTPResponse?.otpDetails)?.length > 0 &&
  //     paidNomineeOTPResponse?.otpDetails?.success
  //   ) {
  //     updateSessionData();
  //     props.onHideModal();
  //     props.handleSuccess && props.handleSuccess();
  //   } else if (
  //     !paidNomineeOTPResponse?.hasData &&
  //     paidNomineeOTPResponse?.codeStatus === "failure"
  //   ) {
  //     if (paidNomineeOTPResponse?.error?.code === "498") {
  //       props.showSessionExpirePopUp && props.showSessionExpirePopUp();
  //     } else if (
  //       paidNomineeOTPResponse?.error?.code?.toString() === "015" ||
  //       paidNomineeOTPResponse?.error?.code?.toString() === "014"
  //     ) {
  //       props.showFrequentCodePopUp(true);
  //     }else if(paidNomineeOTPResponse?.error?.response?.data?.code === "013"){
  //       setResendLimitExceeded(true)        
  //     }else if(paidNomineeOTPResponse?.codeExpired){
  //       setResendLimitExceeded(false)
  //     } else {
  //       setError(t("label_something_went_wrong"));
  //     }
  //   }
  //   if (
  //     !paidNomineeOTPResponse?.otpDetails?.otp &&
  //     !paidNomineeOTPResponse?.otpDetails?.success &&
  //     (paidNomineeOTPResponse?.error?.code?.toString() ===
  //       "014" ||
  //       paidNomineeOTPResponse?.error?.code?.toString() ===
  //         "015")
  //   ) {
  //     setError(t("label_incorrect_OTP"));
  //   }

  //   if(paidNomineeOTPResponse?.error?.response?.data?.code === "013"){
  //     setResendLimitExceeded(true)      
  //   } 
  // }, [
  //   paidNomineeOTPResponse,
  //   paidNomineeOTPResponse?.hasData,
  //   paidNomineeOTPResponse?.isLoading,
  //   props,
  // ]);
  const handleChange = (e: any, otp: any) => {
    setOtpKey(otp);
    setOtp(otp);
    setError("");
    dispatch(resetOTPData());
    dispatch(resetChangePasswordOTPData());
    dispatch(resetCRMOTPData());
  };
  const handleResendOTP = async () => {
    if (props.keyLoggedInProfile === "ROP") {
      const otpRef: any = sessionStorage.getItem("refNumber");
      await dispatch(resendOTPClear());
      if (props.isChangePassword) {
        const otpRefKey: any = sessionStorage.getItem("refNumber");
        if (otpRefKey) {
          dispatch(changePasswordResendOTPPending({ otpRefKey: otpRefKey }));
        }
      } else if (props.page === "paid-nominee") {
        const otpRefKey: any = sessionStorage.getItem("refNumber");
        dispatch(resendNomineeOTPRequest());
      } else if (otpRef) {
        dispatch(resendOTPPending({ otpRef: otpRef }));
      }
      setResend(59);
    } else if (props.keyLoggedInProfile === "CRM") {
      // new resend api for CRM
      const otpRef: any = sessionStorage.getItem("refNo");
      await dispatch(crmResendOTPClear());
      if (otpRef) {
        dispatch(crmResendOTPPending({ otpRef: otpRef }));
      }
      setResend(59);

      if (props?.page === "paid-nominee") {
        const otpRefKey = sessionStorage
          .getItem("refNumber")
          ?.replace(/"/g, "")
          .trim();
        const paidNomineeData: any = {
          otpRefKey,
          otp,
          nomineeDetails: props?.data,
        };
        if (otpRefKey && otp) {
          dispatch(submitNomineeOTPRequest(paidNomineeData));
        }
      }
    }
  };

  const handleOTPVerification = () => {
    if (props.keyLoggedInProfile === "ROP") {
      const otpRef = sessionStorage.getItem("refNumber");
      const data: any = { otpRef, otpKey };
      const newErrors: Partial<OTPFormData> = {};

      if (!otpKey.trim()) {
        newErrors[otpKey as keyof OTPFormData] = getFieldLabel(
          otpKey as keyof OTPFormData
        );
      }
      if (Object.keys(newErrors).length === 0) {
        // setIsValid(true);
        // setIsSuccess(true);
        // sessionStorage.setItem("reset", "true");
      }
      setErrors(newErrors);
      if (props?.isChangePassword) {
        const otpRefKey = sessionStorage.getItem("refNumber");
        const newPin = sessionStorage.getItem("newPin");
        const changePasswordData: any = { otpRefKey, otp, newPin };
        if (otpRefKey && otp && newPin) {
          dispatch(sendChangePasswordOTPPending(changePasswordData));
        }
      } else if (otpKey && otpRef && props?.page !== "paid-nominee") {
        dispatch(sendOTPPending(data));
      }
    }
    if (props.keyLoggedInProfile === "CRM") {
      const otpNumber = otpKey;
      const data: any = { otpNumber };
      if (otpKey) {
        dispatch(sendCRMOTPPending(data));
      }
    }
  };
  const getFieldLabel = (fieldName: keyof OTPFormData) => {
    const labels: { [key in keyof OTPFormData]: string } = {
      otpKey: t("label_incorrect_OTP"),
      otpRef: "",
    };
    return labels[fieldName];
  };

  return (
    <>
      <div className={styles["form-container"]}>
        <div>
          {!error.includes(t("label_too_many_otp_codes")) ? (
            <>
              <div className={styles["headingMsg"]}>
                {t("label_heading")}
                <span className={styles["subHeadingMsg"]}>{maskedMail}</span>
              </div>
              <div className={styles["subHeadingMsg"]}>
                {t("label_textContent")}
              </div>
            </>
          ) : (
            <div className={styles.errorContainer}>
              <TGIcon icon="error-icon" fillColor="" size="24" />
              <div>{t("label_too_many_otp_codes")}</div>
            </div>
          )}
          {error &&
            error.includes(t("label_incorrect_OTP")) &&
            error.includes(t("label_OTP_Code_expired")) && (
              <div className={styles.errorContainer}>
                <TGIcon icon="error-icon" fillColor="" size="24" />
                <span className={styles.errorLabel}>{error}</span>
              </div>
            )}
          <TGOTPInput
            label={""}
            onChange={handleChange}
            type="text"
            name={"otpKey"}
            value={otpKey}
            isError={
              error !== t("label_something_went_wrong") &&
              error !== t("label_too_many_otp_codes") &&
              !!error
            }
            errorLabel={
              error !== t("label_something_went_wrong") &&
              error !== t("label_too_many_otp_codes") &&
              error
            }
          />
          <div
            className={`${
              resendTimer > 0
                ? styles["resend-otp-container"]
                : styles["resend-otp-main"]
            }`}
          >
            {resendTimer > 0 ? (
              <div className={styles.resendOtp} style={{ cursor: "pointer" }}>
                <span className="me-1">
                  <TGIcon icon="refresh-icon-otp" fillColor="none" size="18" />
                </span>
                <span className={styles["resend-timer-font"]}>{`${t(
                  "label_resend_otp_timer"
                )} ${resendTimer}s`}</span>
              </div>
            ) : (
              <div
                className={styles.resendOTP}
                style={{ cursor: "pointer" }}
                onClick={handleResendOTP}
              >
                <span className="me-1">
                  <TGIcon icon="refresh-icon" fillColor="none" size="18" />
                </span>
                <span>{t("label_resend_otp")}</span>
              </div>
            )}

            <div className={styles.resendOtp}>
              {t("label_subhHeading")}{" "}
              {props.keyLoggedInProfile === "CRM"
                ? sessionStorage.getItem("refNo")
                : sessionStorage.getItem("refNumber")?.replace(/"/g, "").trim()}
            </div>
          </div>
        </div>
        <div className={styles["footer"]}>
          <TGButtonVariants
            label={response.isLoading ? "" : t("button_confirm")}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="30px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            bgColor={"#684B9B"}
            bgColorHover="#684B9B"
            bgColorDisabled="#E3D4FE"
            padding="12px 24px"
            textColor="#FFFFFF"
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="160%"
            onClick={handleOTPVerification}
            width="100%"
            disabled={otpKey.length <= 0 ? true : false}
          >
            {response.isLoading ? (
              <>
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
              </>
            ) : null}
          </TGButtonVariants>
        </div>
      </div>
    </>
  );
};

export default LoginOTP;
