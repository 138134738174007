import { Button, Card } from "react-bootstrap";
import styles from "./card-full-image-component.module.scss";
import TGIcon from "../../../shared/tg-icon";

interface cardProps {
    data: {
        buttonText: string;
        heading: string;
        imageUrl: string;
        redirectionUrl: string;
        type?: string | null;
    }
}

const FullImageCard: React.FC<cardProps> = ({ data }) => {
    const { buttonText, heading, imageUrl, redirectionUrl, type } = data;
    const handleButtonClick = () => {
        window.open(redirectionUrl, '_blank')
    }
    return (
        
        <Card className={styles.card} style={{marginLeft: type === "vertical" ? "0px":"0"}}>
            <Card.Img
                src={imageUrl}
                alt="Card image"
                className={type === "vertical" ? styles.cardImageVertical : styles.cardImage}
            />
            <Card.ImgOverlay>
                <Card.Body className={styles.content} style={{position: "absolute",left: type === "vertical" ? "0":"16px",bottom: type === "vertical" ? "0":"16px" }}>
                    <Card.Title className={styles.cardTitle}>
                        {heading}
                    </Card.Title>
                    <Button className={styles.cardButton} onClick={handleButtonClick}>
                        <div className={styles.textContainer}>
                            <span className={styles.text}>{buttonText}</span>
                            <TGIcon icon={"arrow-right"} fillColor="#684B9B" size="20"></TGIcon>
                        </div>
                    </Button>
                </Card.Body>
            </Card.ImgOverlay>
        </Card>
    )
}

export default FullImageCard;