// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SnXsdJuiW2GR_IAYzmuI {
  background-color: rgba(52, 64, 84, 0.7);
  position: fixed;
  top: 84px;
  left: 0;
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  z-index: 3;
  justify-content: flex-start;
  align-items: flex-end;
}
@media (max-width: 1080px) {
  .SnXsdJuiW2GR_IAYzmuI {
    top: 56px;
  }
}
@media (max-width: 480px) {
  .SnXsdJuiW2GR_IAYzmuI {
    padding: 0px !important;
    align-items: unset;
    height: calc(100vh - 56px);
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/mini-profile/mini-profile.module.scss"],"names":[],"mappings":"AAAA;EACI,uCAAA;EACA,eAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;EACA,2BAAA;EACA,qBAAA;AACJ;AACI;EAbJ;IAcQ,SAAA;EAEN;AACF;AAAI;EAjBJ;IAkBQ,uBAAA;IACA,kBAAA;IACA,0BAAA;IACA,gBAAA;IACA,kBAAA;IACA,kBAAA;EAGN;AACF","sourcesContent":[".miniProfileWrapper {\n    background-color: rgba(52, 64, 84, 0.70);\n    position: fixed;\n    top: 84px;\n    left: 0;\n    width: 100vw;\n    height: 90vh;\n    display: flex;\n    flex-direction: column;\n    z-index: 3;\n    justify-content: flex-start;\n    align-items: flex-end;\n\n    @media (max-width: 1080px) {\n        top: 56px;\n    }\n\n    @media (max-width:480px) {\n        padding: 0px !important;\n        align-items: unset;\n        height: calc(100vh - 56px);\n        overflow-y: auto;\n        overflow-x: hidden;\n        position: absolute;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniProfileWrapper": `SnXsdJuiW2GR_IAYzmuI`
};
export default ___CSS_LOADER_EXPORT___;
