import React, { useState } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import TGModal from "../tg-modal";
import TGButtonVariants from "../tg-button-variants";
import TGIcon from "../tg-icon";
import styles from "./nominee-quota.module.scss";
import useScreenSize from "../../utils/hook/useScreenSize";

interface Props{
   memberIcon: string;
   nomineeDetails?: any;
   handleClose: () => void;
   showNomineeQuotaPopup: boolean
}
const NomineeQuota = (props: Props) => {
  const [show, setShow] = useState(true);
  const { i18n } = useTranslation();
  const handleOpen = () => setShow(true);
  const isMobileView: boolean = useScreenSize().device === "mobile";


  return (
    <>
      <TGModal
          show={props.showNomineeQuotaPopup}
          handleClose={props.handleClose}
          buttonLabel="OK"
          customModalClass={styles.nomineeModal}
          customOffCanvasClass={styles.nomineeQuotaCanvasClass}
          centered={true}
          backdrop="static"
          buttonPresent={true}
        >
          <span className={styles.nomineeQuotaHeader}>
            {t("label_nominee_quota").slice(0, -1)}{" "}
            <TGIcon fillColor={"none"} icon={props?.memberIcon} />
          </span>
          <br />
          <br />
          <table className={styles.nomineeQuotaTable}>
            <thead>
              <tr className={styles.nomineeQuotaTableHeader}>
                <th className={styles["nominee-quota-detail-label"]}>
                  <span className={styles.tableDescription}>
                    {t("label_nominee_detail_header")}
                  </span>
                </th>
                <th className={styles["nominee-quota-current-label"]}>
                  <span className={styles.tableDescription}>
                    {t("label_nominee_current_header")}
                  </span>
                </th>
                <th className={styles["nominee-quota-max-label"]}>
                  <span className={styles.tableDescription}>
                    {t("label_nominee_max_allowed_header")}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles["nominee-row"]}>
                <td className={styles["nominee-row-description"]}>
                  {t("label_current_nominee_description")}
                </td>
                <td className={styles.currentNominee}>
                  {props.nomineeDetails.existingNominee}
                </td>
                <td className={styles.maxNominee}>
                  {props.nomineeDetails.maxNumberOfNominees}
                </td>
              </tr>

              <tr className={styles["nominee-row"]}>
                <td className={styles["nominee-row-description"]}>
                  {t("label_nominees_no_fee_applied_description")}
                </td>
                <td className={styles.currentNominee}>
                  {props.nomineeDetails.currentFreeInsertsForCurrentTier}
                </td>
                <td className={styles.maxNominee}>
                  {props.nomineeDetails.maxFreeInsertsForCurrentTier}
                </td>
              </tr>

              <tr className={styles["nominee-row"]}>
                <td className={styles["nominee-row-description"]}>
                  {t("label_nominees_fee_applied_description")}
                </td>
                <td className={styles.currentNominee}>
                  {props.nomineeDetails.currentPaidInsertsForCurrentTier}
                </td>
                <td className={styles.maxNominee}>
                  {props.nomineeDetails.maxPaidInsertsForCurrentTier}
                </td>
              </tr>

              <tr className={styles["nominee-row"]}>
                <td className={styles["nominee-row-description"]}>
                  {t("label_nominees_complimentary_description")}
                </td>
                <td className={styles.currentNominee}>
                  {props.nomineeDetails.currentInitialFreeInserts}
                </td>
                <td className={styles.maxNominee}>
                  {props.nomineeDetails.maxInitialFreeInserts}
                </td>
              </tr>
            </tbody>
          </table>
          <div></div>
        </TGModal>
    </>
  );
};

export default NomineeQuota;
