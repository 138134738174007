import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Tab, Tabs } from "react-bootstrap";
import styles from "./login-widget.module.scss";
import { useTranslation } from "react-i18next";
import SignIn from "./sign-in/index";
import LoginOTP from "./login-otp";
import TGIcon from "../tg-icon";
import useScreenSize from "../../utils/hook/useScreenSize";
import { resetLoginData } from "../../OSCI/src/slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import AccountLocked from "./sign-in/account-locked";
import FrequentOTP from "./sign-in/frequent-otp";
import { RootState } from "../../OSCI/src/slice/RootReducer";
import { resetCrmSocialValidationData } from "../../OSCI/src/slice/crmLoginSlice";

interface Props {
  onHidePanel: () => void;
  flow?: string;
  loginName: (name: string) => void;
  handlePanelShow: () => void;
  handleSuccess?: () => void;
  tabbedName?: string;
  isTabbed?: boolean;
}

const LoginWidget: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const [fullscreen, setFullScreen] = useState<true | undefined>(undefined);
  const isMobileView: boolean = useScreenSize().device === "mobile";
  const [loginShow, setloginShow] = useState<boolean>(true);
  const [enabledAccountLocked, setEnabledAccountLocked] =
    useState<boolean>(false);
  const [enabledTooManyCode, setEnabledTooManyCode] = useState<boolean>(false);
  const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
  const [key, setKey] = useState("ROP");
  const dispatch = useDispatch();

  const crmLoginResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.crmLoginData
  );
  const crmOTPResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.crmotpData
  );

  // crm login open otp popup after loggedin api success
  useEffect(() => {
    if (crmLoginResponse?.codeStatus === "success") {
      setloginShow(false);
    }
  }, [crmLoginResponse, crmLoginResponse?.codeStatus]);

  useEffect(() => {
    if (crmOTPResponse?.codeStatus === "success") {
      sessionStorage.removeItem("temptoken");
      sessionStorage.removeItem("refNo");
    }
  }, [crmOTPResponse?.codeStatus, crmOTPResponse]);

  useEffect(() => {
    if (props?.flow == "enroll") {
      setloginShow(false);
    }
  }, [props.flow]);
  useEffect(() => {
    if (props.tabbedName == "CRM") {
      setKey("CRM");
    }
  }, [props.tabbedName]);
  useEffect(() => {
    if (isMobileView) {
      setFullScreen(true);
    } else {
      setFullScreen(undefined);
    }
  });

  useEffect(() => {
    document.documentElement.style.setProperty("--body-overflow", "hidden");
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, []);

  const showOrHidePanel = (value: boolean) => {
    setloginShow(value);
  };

  const showOrHideAccountLockedPopUp = (value: boolean) => {
    setEnabledAccountLocked(value);
    setloginShow(false);
  };
  const showOrHideTooManyCodesPopUp = (value: boolean) => {
    setEnabledTooManyCode(value);
    setloginShow(false);
    sessionStorage.removeItem("temptoken");
    sessionStorage.removeItem("refNumber");
    sessionStorage.removeItem("memberId");
    sessionStorage.removeItem("reset");
  };

  const showSessionExpirePopUp = () => {
    setIsSessionExpired(true);
    setloginShow(false);
    sessionStorage.removeItem("temptoken");
    sessionStorage.removeItem("refNumber");
    sessionStorage.removeItem("memberId");
    sessionStorage.removeItem("reset");
  };

  const handleClosePanel = () => {
    if (isSessionExpired) {
      setloginShow(true);
    }
  };

  const renderTabContent = () => {
    switch (key) {
      case "ROP":
        return (
          <SignIn
            onHideModal={props.onHidePanel}
            isHidden={showOrHidePanel}
            updateUserName={props.loginName}
            showAccountLockedPopUp={showOrHideAccountLockedPopUp}
            showFrequentCodePopUp={showOrHideTooManyCodesPopUp}
            loginProfile="ROP"
            key={key}
          />
        );
      case "CRM":
        return (
          <SignIn
            onHideModal={props.onHidePanel}
            isHidden={showOrHidePanel}
            updateUserName={props.loginName}
            showAccountLockedPopUp={showOrHideAccountLockedPopUp}
            showFrequentCodePopUp={showOrHideTooManyCodesPopUp}
            loginProfile="CRM"
            key={key}
          />
        );
      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <>
      {enabledAccountLocked && <AccountLocked hidePanel={props.onHidePanel} />}
      {enabledTooManyCode && <FrequentOTP hidePanel={props.onHidePanel} />}
      {isSessionExpired && (
        <FrequentOTP
          hidePanel={handleClosePanel}
          isSessionExpired={true}
          setIsSessionExpired={setIsSessionExpired}
        />
      )}
      {!enabledTooManyCode && !enabledAccountLocked && !isSessionExpired && (
        <Modal
          show={true}
          onHide={props.onHidePanel}
          centered
          backdrop="static"
          className={`${
            key === "ROP"
              ? styles["sign-in-rop-modal"]
              : styles["sign-in-modal"]
          } ${fullscreen ? styles["parentContainer"] : ""} ${
            !loginShow && !fullscreen && styles["modal-container-rop-flow"]
          }`}
          fullscreen={fullscreen}
        >
          {loginShow ? (
            <>
              <Modal.Header
                className={styles["tabbed-modal-header"]}
                closeButton
              >
                <div
                  className={`${styles["sign-in-tab"]} ${
                    key === "ROP" ? styles["active"] : styles["default"]
                  }`}
                  onClick={() => setKey("ROP")}
                >
                  {t("label_sign_in_pop_up_rop")}
                </div>
                <div
                  className={`${styles["sign-in-tab"]} ${
                    key === "CRM" ? styles["active"] : styles["default"]
                  }`}
                  onClick={() => {
                    dispatch(resetCrmSocialValidationData());
                    setKey("CRM");
                  }}
                >
                  <div>{t("label_sign_in_pop_up_non_member")}</div>
                </div>
              </Modal.Header>
              <Modal.Body>{renderTabContent()}</Modal.Body>
            </>
          ) : (
            <>
              <Modal.Header closeButton className={styles.borderBottom}>
                <Modal.Title className={styles.titleWrapper}>
                  {props.flow !== "enroll" && (
                    <span
                      className="arrow"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setloginShow(true);
                        dispatch(resetLoginData());
                      }}
                    >
                      <TGIcon icon="arrow-left" fillColor="#684B9B" size="20" />
                    </span>
                  )}
                  <div className={styles.otpTitleSignIn}>
                    {t("label_otp_title")}
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={styles.otpModalBody}>
                <LoginOTP
                  onHideModal={props.onHidePanel}
                  isHidden={showOrHidePanel}
                  updateUserName={props.loginName}
                  showFrequentCodePopUp={showOrHideTooManyCodesPopUp}
                  showSessionExpirePopUp={showSessionExpirePopUp}
                  handleSuccess={props?.handleSuccess}
                  keyLoggedInProfile={key}
                />
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default LoginWidget;
