// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.aqIew8JYyPFM2Y32otrd {
  width: 100%;
}

.NMAiGJMpTdbb2PwHrjvz {
  display: flex;
  padding: 11px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid var(--gray-stroke, #DFE0EB);
  background: var(--gray-surface-invert, #FFF);
  cursor: pointer;
}
.NMAiGJMpTdbb2PwHrjvz:hover {
  background-color: #eee6fd;
}
.NMAiGJMpTdbb2PwHrjvz:active {
  background-color: #f3eefa;
}

.Az5eccs1QCHbSTF79UJu {
  background-color: #684B9B !important;
}

.ldf1LoGOhU_VfYLHybnq {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.BTDEbVf9XT8DBOK1eFjl {
  color: var(--gray-text-strong, #2C3059);
  text-align: center;
  /* Body/Body — Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 25.6px */
}`, "",{"version":3,"sources":["webpack://./src/shared/login-widget/sign-in/social-signin/social-signin.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,WAAA;AAEJ;;AACA;EACI,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,6CAAA;EACA,4CAAA;EACA,eAAA;AAEJ;AAAI;EACI,yBAAA;AAER;AACI;EACI,yBAAA;AACR;;AAGA;EACI,oCAAA;AAAJ;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AAAJ;;AAGA;EACI,uCAAA;EACA,kBAAA;EAEA,qBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AADJ","sourcesContent":[".social-button-wrapper {\n    width: 100%;\n}\n\n.signin-social-media {\n    display: flex;\n    padding: 11px 20px;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n    border-radius: 100px;\n    border: 1px solid var(--gray-stroke, #DFE0EB);\n    background: var(--gray-surface-invert, #FFF);\n    cursor: pointer;\n\n    &:hover {\n        background-color: #eee6fd;\n    }\n\n    &:active {\n        background-color: #f3eefa;\n    }\n}\n\n.button-background {\n    background-color: #684B9B !important;\n}\n\n.button-content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 12px;\n    flex: 1 0 0;\n}\n\n.sign-in-text {\n    color: var(--gray-text-strong, #2C3059);\n    text-align: center;\n\n    /* Body/Body — Bold */\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 160%;\n    /* 25.6px */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social-button-wrapper": `aqIew8JYyPFM2Y32otrd`,
	"signin-social-media": `NMAiGJMpTdbb2PwHrjvz`,
	"button-background": `Az5eccs1QCHbSTF79UJu`,
	"button-content": `ldf1LoGOhU_VfYLHybnq`,
	"sign-in-text": `BTDEbVf9XT8DBOK1eFjl`
};
export default ___CSS_LOADER_EXPORT___;
