import React, { useEffect, useState } from "react";
import TGCheckBox from "../../tg-checkbox";
import styles from "../sign-in/sign-in.module.scss";
import TGButtonVariants from "../../tg-button-variants";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../utils/hook/useScreenSize";
import TGIconLabel from "../../tg-icon-label";
import TGInput from "../../tg-input";
import {
  ROPLoginFormData,
  CRMLoginFormData,
} from "../../../OSCI/src/models/models";
import { Offcanvas, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import TGIcon from "../../tg-icon";
import {
  getLoginSectionDataPending,
  resetLoginData,
} from "../../../OSCI/src/slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../OSCI/src/slice/RootReducer";
import { useNavigate } from "react-router-dom";
import { resendOTPClear } from "../../../OSCI/src/slice/resendSlice";
import {
  getCrmLoginDataPending,
  resetCrmLoginData,
} from "../../../OSCI/src/slice/crmLoginSlice";
import SocialSignIn from "./social-signin";
import TGGenericErrorDialog from "../../../shared/tg-generic-error-dialog";

interface Props {
  isHidden: (value: boolean) => void;
  onHideModal: (value: boolean) => void;
  updateUserName: (name: string) => void;
  showAccountLockedPopUp: (value: boolean) => void;
  showFrequentCodePopUp: (value: boolean) => void;
  loginProfile: string;
  key: string;
}

const SignIn = (props: Props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isDesktopView = useScreenSize().deviceSize.width > 767;
  const navigate = useNavigate();
  const handleClick = () => {
    window.location.href = "/rop/resetpassword";
  };
  const handleCRMClick = () => {
    window.location.href = "/resetpassword";
  };
  const handleJoinNowClick = () => {
    navigate(`/${i18n.language}/signup`, {
      state: { signUpType: props.loginProfile },
    });
    props.onHideModal(false);
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(true);
  const [ropFormData, setRopFormData] = useState<ROPLoginFormData>({
    memberId: "",
    password: "",
  });
  const [crmFormData, setCrmFormData] = useState<CRMLoginFormData>({
    email: "",
    password: "",
  });
  const [ropErrors, setRopErrors] = useState<Partial<ROPLoginFormData>>({});
  const [crmErrors, setCrmErrors] = useState<Partial<CRMLoginFormData>>({});

  const [isValid, setIsValid] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 18);
  const isMobileView: boolean = useScreenSize().device === "mobile";
  const [isChecked, setIsChecked] = useState(false);
  const [showMobileToolTip, setShowMobileToolTip] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [crmErrorMessages, setCrmErrorMessages] = useState("");
  const response: any = useSelector(
    (state: RootState) => state?.osciReducer?.loginData
  );

  let errorMessage: any = useSelector(
    (state: RootState) => state?.osciReducer?.loginData.error
  );
  let errorInvalidOTP: string = useSelector(
    (state: RootState) => state?.osciReducer?.otpData.error
  );

  const crmLoginResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.crmLoginData
  );

  // const { clientIdGoogle, clientIdTestGoogle } = constant;

  useEffect(() => {
    setRopFormData({
      memberId: "",
      password: "",
    });
    setCrmFormData({
      email: "",
      password: "",
    });
  }, [props.key]);

  useEffect(() => {
    let rememberMeEnabled;
    if (props.loginProfile === "ROP") {
      rememberMeEnabled = localStorage.getItem("memberId")?.toString() || "";
    }
    if (props.loginProfile === "CRM") {
      rememberMeEnabled = localStorage.getItem("email")?.toString() || "";
    }
    if (rememberMeEnabled) {
      setIsChecked(true);
      setRopFormData({ ...ropFormData, memberId: rememberMeEnabled });
      setCrmFormData({ ...crmFormData, email: rememberMeEnabled });
    }

    return () => {
      dispatch(resetLoginData());
      dispatch(resetCrmLoginData());
      setErrorMessages("");
      setCrmErrorMessages("");
    };
  }, [props.loginProfile]);

  useEffect(() => {
    dispatch(resendOTPClear());
  }, []);

  useEffect(() => {
    if (props.loginProfile === "ROP") {
      if (
        response?.hasData &&
        !response.isLoading &&
        response?.loginDetails?.otpRefKey !== ""
      ) {
        props.isHidden(false);
        if (isChecked) {
          localStorage.setItem("memberId", ropFormData.memberId);
        }
      } else if (
        !response.hasData &&
        response?.loginDetails?.otpRefKey === undefined &&
        response.codeStatus === "failure"
      ) {
        props.isHidden(true);
        setError(true);
        if (
          errorMessage?.response?.data?.code === "40100002" ||
          errorMessage?.response?.data?.code === "E001" ||
          errorMessage?.response?.data?.code === "E500"
        ) {
          !ropErrors.memberId && setRopFormData({ memberId: "", password: "" });
          setErrorMessages(t("label_credentails_incorrect"));
        } else if (
          errorMessage?.response?.data?.code === "E0199" ||
          errorMessage?.response?.data?.code === "007"
        ) {
          props.showAccountLockedPopUp(true);
        } else if (errorMessage?.response?.data?.code?.toString() === "1007") {
          //TODO show the popup for too many OTP ;
          //setErrorMessages(t("label_too_many_otp_codes"));
          props.showFrequentCodePopUp(true);
        } else {
          setErrorMessages(t("label_something_went_wrong"));
        }
      }
    }
  }, [response?.hasData, response?.isLoading, response?.loginDetails]);

  useEffect(() => {
    if (props.loginProfile === "CRM") {
      if (
        crmLoginResponse?.hasData &&
        !crmLoginResponse.isLoading &&
        crmLoginResponse?.crmLoginDetails?.refNo !== ""
      ) {
        props.isHidden(false);
        // props.onHideModal(false);
        if (isChecked) {
          localStorage.setItem("email", crmFormData.email);
        }
      } else if (
        !crmLoginResponse.hasData &&
        crmLoginResponse.codeStatus === "failure"
      ) {
        props.isHidden(true);
        setError(true);
        if (crmLoginResponse?.error?.response?.data?.Status === 400) {
          if (crmLoginResponse?.error?.response?.data?.Code === "998") {
            props.showAccountLockedPopUp(true);
          } else if (crmLoginResponse?.error?.response?.data?.Code === "997") {
            setCrmFormData({
              email: "",
              password: "",
            });
            setCrmErrorMessages(t("label_credentails_incorrect"));
          } else if (crmLoginResponse?.error?.response?.data?.Code === "013") {
            props.showFrequentCodePopUp(true);
          }
          else {
            setCrmErrorMessages(t("label_something_went_wrong"));
          }
        } 
        else {
          setCrmErrorMessages(t("label_something_went_wrong"));
        }
      }
    }
  }, [
    crmLoginResponse?.hasData,
    crmLoginResponse?.isLoading,
    crmLoginResponse?.crmLoginDetails,
  ]);

  const isValidMemberId = (memberId: string) => {
    return /^[A-Z]{2}\d{5}$/.test(memberId);
  };

  const isValidEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const getFieldLabel = (key: string, loginProfile: string) => {
    if (loginProfile === "ROP") {
      const labels: { [key in keyof ROPLoginFormData]: string } = {
        memberId: t("signin_rop_required"),
        password: t("signin_password_required"),
      };
      return labels[key as keyof ROPLoginFormData];
    }
    if (loginProfile === "CRM") {
      const labels: { [key in keyof CRMLoginFormData]: string } = {
        email: t("signin_email_required"),
        password: t("signin_password_required"),
      };
      return labels[key as keyof CRMLoginFormData];
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    loginInput: string
  ) => {
    if (loginInput === "ROP") {
      const { name, value } = event.target;
      setRopFormData({
        ...ropFormData,
        [name]: name === "memberId" ? value?.toUpperCase() : value,
      });
      setRopErrors({ ...ropErrors, [name]: "" });
      setErrorMessages("");
    }
    if (loginInput === "CRM") {
      const { name, value } = event.target;
      setCrmFormData({ ...crmFormData, [name]: value });
      setCrmErrors({ ...crmErrors, [name]: "" });
      setCrmErrorMessages("");
    }
    setError(false);
    if (!isChecked) {
      if (loginInput === "ROP") {
        localStorage.removeItem("memberId");
      }
      if (loginInput === "CRM") {
        localStorage.removeItem("email");
      }
    }
  };

  const handleSubmit = (
    event: React.FormEvent<HTMLButtonElement>,
    loginProfile: string
  ) => {
    event.preventDefault();

    if (loginProfile === "ROP") {
      const newErrors: Partial<ROPLoginFormData> = {};
      Object.entries(ropFormData).forEach(([key, value]) => {
        if (!value.trim()) {
          newErrors[key as keyof ROPLoginFormData] = getFieldLabel(
            key,
            loginProfile
          );
        } else if (
          key === "memberId" &&
          !isValidMemberId(value.toUpperCase())
        ) {
          newErrors[key as keyof ROPLoginFormData] = t(
            "error_reset_ropid_invalid"
          );
        }
      });
      setRopErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        setIsValid(true);
        setIsSuccess(true);
        sessionStorage.setItem("reset", "true");
      } else {
        setIsSuccess(false);
      }
      if (ropFormData.memberId && ropFormData.password) {
        sessionStorage.setItem("memberId", ropFormData.memberId);
        dispatch(
          getLoginSectionDataPending({
            memberId: ropFormData.memberId?.toUpperCase(),
            password: ropFormData.password,
          })
        );
      }
    }
    if (loginProfile === "CRM") {
      const newErrors: Partial<CRMLoginFormData> = {};
      Object.entries(crmFormData).forEach(([key, value]) => {
        if (!value.trim()) {
          newErrors[key as keyof CRMLoginFormData] = getFieldLabel(
            key,
            loginProfile
          );
        } else if (key === "email" && !isValidEmail(value)) {
          newErrors[key as keyof CRMLoginFormData] = t("reset_invalid_message");
        }
      });
      setCrmErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        setIsValid(true);
        if (crmFormData.email && crmFormData.password) {
          sessionStorage.setItem("email", crmFormData.email);
          dispatch(
            getCrmLoginDataPending({
              email: crmFormData.email,
              password: crmFormData.password,
            })
          );
        }
        setIsSuccess(true);
        sessionStorage.setItem("reset", "true");
      } else {
        setIsSuccess(false);
      }
    }
  };

  const checkedChange = (e: any, loginInput: string): void => {
    if (e?.target?.checked) {
      if (loginInput === "ROP") {
        localStorage.setItem("memberId", ropFormData.memberId);
      }
      if (loginInput === "CRM") {
        localStorage.setItem("email", crmFormData.email);
      }
    } else {
      localStorage.removeItem("memberId");
      localStorage.removeItem("email");
    }
    setIsChecked(!isChecked);
  };

  const loginType = (loginInput: string) => {
    switch (loginInput) {
      case "ROP":
        return (
          <div>
            <div className={styles.formInputWrapper}>
              <TGInput
                label={t("label_reset_ropid")}
                onChange={(e) => handleChange(e, loginInput)}
                isError={!!ropErrors.memberId}
                errorLabel={ropErrors.memberId}
                name="memberId"
                type="text"
                value={ropFormData.memberId.trim()}
                placeholder={t("label_reset_ropid_placeholder")}
                customClassName={styles.customInput}
                maxLength={7}
                validation="rop"
                focus={true}
              />
              <TGInput
                label={t("label_password")}
                onChange={(e) => handleChange(e, loginInput)}
                isError={!!ropErrors.password}
                errorLabel={ropErrors.password}
                name="password"
                type="password"
                value={ropFormData.password.trim()}
                customClassName={styles.customInput}
                validation="language"
              />
            </div>
            <div className={styles.input}>
              <TGIconLabel
                label={t("label_signin_reset_password")}
                onClick={handleClick}
                position="left"
                icon="null"
              />
              <div className={styles.rememberMeContainer}>
                <TGCheckBox
                  label={t("label_signin_remember_me")}
                  onChange={(e) => checkedChange(e, props.loginProfile)}
                  checked={isChecked}
                  className="check-input"
                  customLabel="rememberMeLabel"
                />

                {isDesktopView && (
                  <OverlayTrigger
                    placement="bottom-end"
                    overlay={
                      <Tooltip className={styles["tooltip-info"]} id="tooltip">
                        <span>{t("label_signin_rememberMeTooltip")}</span>
                      </Tooltip>
                    }
                  >
                    <span>
                      <TGIcon
                        icon={"information-icon"}
                        fillColor="#2C3059"
                        size="15"
                        strokeColor="#2C3059"
                      ></TGIcon>
                    </span>
                  </OverlayTrigger>
                )}
                {!isDesktopView && (
                  // <OverlayTrigger
                  //   placement="bottom"
                  //   trigger="click"
                  //   overlay={
                  //   }
                  // >
                  <>
                    <span
                      onClick={() => {
                        setShowMobileToolTip(true);
                      }}
                    >
                      <TGIcon
                        icon={"information-icon"}
                        fillColor="#2C3059"
                        size="15"
                        strokeColor="#2C3059"
                      ></TGIcon>
                    </span>
                    <Offcanvas
                      className={styles["tooltip-info-mobile"]}
                      show={showMobileToolTip}
                      onHide={() => {
                        setShowMobileToolTip(false);
                      }}
                      placement={"bottom"}
                      backdropClassName={styles["tooltip-info-mobile-backdrop"]}
                      name={"bottom"}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title
                          className={styles["tooltip-info-mobile-title"]}
                        >
                          {t("label_signin_rememberMeTooltip_header")}
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <span className={styles["tooltip-info-mobile-body"]}>
                          {t("label_signin_rememberMeTooltip")}
                        </span>
                        <div className={styles["tooltip-info-mobile-button"]}>
                          <TGButtonVariants
                            label={t("button_too_many_codes_ok")}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="30px"
                            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColor="#684B9B"
                            padding="16px"
                            textColor="#FFFFFF"
                            fontFamily="Inter"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="160%"
                            borderDisabled="0.791px solid #E3D4FE"
                            bgColorDisabled="#E3D4FE"
                            boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColorHover="#684b9b"
                            borderHover="0.791px solid #E3D4FE"
                            boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            width={"100%"}
                            height="50px"
                            onClick={() => {
                              setShowMobileToolTip(false);
                            }}
                          />
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </>
                  // </OverlayTrigger>
                )}
              </div>
            </div>
          </div>
        );

      case "CRM":
        return (
          <div>
            <div className={styles.formInputWrapper}>
              <TGInput
                label={t("label_reset_email")}
                onChange={(e) => handleChange(e, loginInput)}
                isError={!!crmErrors.email}
                errorLabel={crmErrors.email}
                name="email"
                type="email"
                value={crmFormData.email.trim()}
                placeholder={t("label_reset_email_placeholder")}
                customClassName={styles.customInput}
                focus={true}
                validation="language"
              />
              <TGInput
                label={t("label_password")}
                onChange={(e) => handleChange(e, loginInput)}
                isError={!!crmErrors.password}
                errorLabel={crmErrors.password}
                name="password"
                type="password"
                value={crmFormData.password.trim()}
                customClassName={styles.customInput}
                validation="language"
              />
            </div>
            <div className={styles.input}>
              <TGIconLabel
                label={t("label_signin_reset_password")}
                onClick={handleCRMClick}
                position="left"
                icon="null"
              />
              <div className={styles.rememberMeContainer}>
                <TGCheckBox
                  label={t("label_signin_remember_me")}
                  onChange={(e) => checkedChange(e, props.loginProfile)}
                  checked={isChecked}
                  className="check-input"
                  customLabel="rememberMeLabel"
                />

                {isDesktopView && (
                  <OverlayTrigger
                    placement="bottom-end"
                    overlay={
                      <Tooltip className={styles["tooltip-info"]} id="tooltip">
                        <span>{t("label_signin_rememberMeTooltip")}</span>
                      </Tooltip>
                    }
                  >
                    <span>
                      <TGIcon
                        icon={"information-icon"}
                        fillColor="#2C3059"
                        size="15"
                        strokeColor="#2C3059"
                      ></TGIcon>
                    </span>
                  </OverlayTrigger>
                )}
                {!isDesktopView && (
                  // <OverlayTrigger
                  //   placement="bottom"
                  //   trigger="click"
                  //   overlay={
                  //   }
                  // >
                  <>
                    <span
                      onClick={() => {
                        setShowMobileToolTip(true);
                      }}
                    >
                      <TGIcon
                        icon={"information-icon"}
                        fillColor="#2C3059"
                        size="15"
                        strokeColor="#2C3059"
                      ></TGIcon>
                    </span>
                    <Offcanvas
                      className={styles["tooltip-info-mobile"]}
                      show={showMobileToolTip}
                      onHide={() => {
                        setShowMobileToolTip(false);
                      }}
                      placement={"bottom"}
                      backdropClassName={styles["tooltip-info-mobile-backdrop"]}
                      name={"bottom"}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title
                          className={styles["tooltip-info-mobile-title"]}
                        >
                          {t("label_signin_rememberMeTooltip_header")}
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <span className={styles["tooltip-info-mobile-body"]}>
                          {t("label_signin_rememberMeTooltip")}
                        </span>
                        <div className={styles["tooltip-info-mobile-button"]}>
                          <TGButtonVariants
                            label={t("button_too_many_codes_ok")}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="30px"
                            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColor="#684B9B"
                            padding="16px"
                            textColor="#FFFFFF"
                            fontFamily="Inter"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="160%"
                            borderDisabled="0.791px solid #E3D4FE"
                            bgColorDisabled="#E3D4FE"
                            boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColorHover="#684b9b"
                            borderHover="0.791px solid #E3D4FE"
                            boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            width={"100%"}
                            height="50px"
                            onClick={() => {
                              setShowMobileToolTip(false);
                            }}
                          />
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </>
                  // </OverlayTrigger>
                )}
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  const errorArray = errorMessages
    .split(".")
    .map((sentence) => sentence.trim() + ".")
    .filter((sentence) => sentence !== ".");

  const handleSocialMediaModalClose = (value: boolean) => {
    props.onHideModal(value);
  };

  const handleGenericModalClose = () => {
    setSocialSignInError({
      heading: "",
      visible: false,
      message: "",
      buttonText: "",
    });
  };

  return (
    <>
      {props.loginProfile === "ROP" &&
        Object.keys(ropErrors).length === 0 &&
        errorMessages !== "" && (
          <div className={styles.errorContainer}>
            <TGIcon icon="error-icon" fillColor="" size="18" />
            <div>
              {errorArray.map((sentence) => (
                <div className={styles.errorLabel}>{sentence}</div>
              ))}
            </div>
          </div>
        )}
      {props.loginProfile === "CRM" && crmErrorMessages !== "" && (
        <div className={styles.errorContainer}>
          <TGIcon icon="error-icon" fillColor="" size="18" />
          <span className={styles.errorLabel}>{crmErrorMessages}</span>
        </div>
      )}
      <div
        className={`${styles.contentWrapper} ${
          props.loginProfile === "ROP" && styles.contentRopWrapper
        }`}
      >
        <div>{loginType(props.loginProfile)}</div>
        <div className={styles.joinNowSection}>
          <TGButtonVariants
            label={
              response.isLoading || crmLoginResponse.isLoading
                ? ""
                : t("label_signin_header")
            }
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="30px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            bgColor="#684B9B"
            padding="16px"
            textColor="#FFFFFF"
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="160%"
            borderDisabled="0.791px solid #E3D4FE"
            bgColorDisabled="#E3D4FE"
            boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            bgColorHover="#684b9b"
            borderHover="0.791px solid #E3D4FE"
            boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            width={isDesktopView ? "460px" : "100%"}
            height="50px"
            onClick={(e) => handleSubmit(e, props.loginProfile)}
            disabled={
              (ropFormData.memberId.length > 0 &&
                ropFormData.password.length > 0) ||
              (crmFormData.email.length > 0 && crmFormData.password.length > 0)
                ? false
                : true
            }
          >
            {response.isLoading || crmLoginResponse.isLoading ? (
              <>
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
              </>
            ) : null}
          </TGButtonVariants>
          <div className={styles.footerLink}>
            {props.loginProfile === "ROP" ? (
              <div className={styles.joinNowText}>
                {t("label_rop_member_not_now")}
              </div>
            ) : (
              <div className={styles.joinNowInfo}>
                {t("label_member_not_now")}
              </div>
            )}
            <div className={styles.linkClass} onClick={handleJoinNowClick}>
              {t("label_join_now")}
            </div>
          </div>
        </div>
        {props.loginProfile === "CRM" && (
          <>
            <div className={styles["form-container"]}>
              <div className={styles["divider-or"]}>
                <TGIcon fillColor={""} icon={"vector"} />
                <div className={styles["divider-text"]}>or</div>
                <TGIcon fillColor={""} icon={"vector"} />
              </div>
              <div className={styles["social-media"]}>
                <SocialSignIn showModal={handleSocialMediaModalClose} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default SignIn;
