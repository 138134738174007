import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import isAuthenticate from "../../../utils/hook/isAuthenticate";
import {
  getTripDataFailure,
  getTripDataSuccess,
} from "../../../OSCI/src/slice/tripSlice";
import { config } from "../../../config/global";
import { RootState } from "../../../OSCI/src/slice/RootReducer";

import TGMultiCard from "../../tg-multi-card";
import TGButtonVariants from "../../tg-button-variants";
import styles from "./crm-mini-profile.module.scss";
import TGIcon from "../../tg-icon";
import { FunctionContext } from "../../../OSCI/src/component/header";
import { useNavigate } from "react-router-dom";
import { fetchProfileRequest } from "../../../OSCI/src/slice/fetchProfileSlice";
import useScreenSize from "../../../utils/hook/useScreenSize";
import { tripDetails } from "../../../OSCI/src/models/models";
import {
  crmLogoutRequest,
  logoutRequest,
  setNavigateRop,
} from "../../../OSCI/src/slice/logOutSlice";

const CRMMiniProfile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = isAuthenticate();
  const navigate = useNavigate();

  const UPCOMING_TRIPS_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;

  const isMobileView: boolean = useScreenSize().deviceSize?.width < 1081;
  const [profileName, setProfileName] = useState<any>();

  useEffect(() => {
    edgeHandle();
  }, []);

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: UPCOMING_TRIPS_API_URL,
      isTeamsite: false,
      successAction: getTripDataSuccess,
      errorAction: getTripDataFailure,
    });
    getProfileData();
  }, [UPCOMING_TRIPS_API_URL, dispatch]);

  useEffect(() => {
    document.documentElement.style.setProperty("--body-overflow", "hidden");
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, []);

  const trips: tripDetails = useSelector(
    (state: RootState) => state?.osciReducer?.trips?.upcomingTrips
  );

  const recentFlightDetails = {
    pnrDetails: [
      {
        flightDetails:
          Object.keys(trips).length > 0
            ? trips?.pnrDetails[0]?.flightDetails
            : [],
        lastName:
          Object.keys(trips).length > 0 ? trips?.pnrDetails[0]?.lastName : "",
        pnr: Object.keys(trips).length > 0 ? trips?.pnrDetails[0]?.pnr : "",
        tripType:
          Object.keys(trips).length > 0 ? trips?.pnrDetails[0]?.tripType : "",
        segmentGroups:
          Object.keys(trips).length > 0
            ? trips?.pnrDetails[0]?.segmentGroups
            : "",
      },
    ],
  };

  const navigateToSignup = () => {
    dispatch(setNavigateRop(true));
    navigate(`/${i18n.language}/signup`);
  };

  const handleSeeAll = () => {
    console.log("To Be Implemented!");
  };

  const handleProfile = () => {
    window.open(`/${i18n.language}/member/myprofile`, "_blank");
  };

  const closeCRMMiniProfile = useContext(FunctionContext);

  const handleLogout = () => {
    //dummy implementation, once API available can be integrated

    dispatch(crmLogoutRequest());
    document.documentElement.style.setProperty("--loading-spinner", "block");
  };

  function NoupcomingTrips() {
    return (
      <div
        className={
          isMobileView ? styles.imageContainerMobile : styles.imageContainer
        }
      >
        <img src="/assets/noUpcomingTrips.svg" alt="No Upcoming Trips" />
        <p className={styles.imgText}>{t("label_no_upcoming_trips")}</p>
      </div>
    );
  }

  function getProfileData() {
    if (isMobileView) {
      const profileUserName = sessionStorage.getItem("FullName");
      if (profileUserName) {
        var getName = profileUserName;
        let name = getName.split(" ");
        let displayName = name[1] ? name[1] : name[0];
        sessionStorage.setItem(
          "profileName",
          displayName?.charAt(0).toUpperCase() +
            displayName?.slice(1).toLowerCase()
        );
        var profileName = sessionStorage.getItem("FullName");
        setProfileName(profileName);
      }
    } else {
      var profileName = sessionStorage.getItem("FullName");
      setProfileName(profileName);
    }
  }

  const edgeHandle = () => {
    if (
      window.navigator.userAgent.indexOf("Edg") > -1 &&
      window.innerWidth < 480
    ) {
      document
        .getElementById("crm-mini")
        ?.setAttribute("style", "height: calc(100vh - 100px)");
      document
        .getElementById("button-frame")
        ?.setAttribute("style", "top: calc(100vh - 180px)");
    } else {
      var isSafari =
        /Safari/.test(window.navigator.userAgent) &&
        /Apple Computer/.test(window.navigator.vendor);
      if (isSafari) {
        document
          .getElementById("button-frame")
          ?.setAttribute("style", "top: calc(100vh - 70px)");
      }
    }
  };

  return (
    <div id="crm-mini" className={styles["mini-profile-container"]}>
      <div className={styles["mini-profile-content-frame"]}>
        <div
          className={styles["close-icon-container"]}
          onClick={closeCRMMiniProfile}
        >
          <TGIcon icon="mini-profile-close" fillColor="" />
        </div>
        <div className={styles["profile-container"]}>
          <div className={styles["profile-name-text"]}>{profileName}</div>
        </div>

        <div className={styles["miles-container"]}>
          <div className={styles["miles-description"]}>
            {t("label_crm_registerropstatement")}
          </div>
          <TGButtonVariants
            label={t("button_rop_signin")}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="30px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            bgColor="#684B9B"
            padding="10px 20px"
            textColor="#FFFFFF"
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="160%"
            borderDisabled="0.791px solid #E3D4FE"
            bgColorDisabled="#E3D4FE"
            boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            bgColorHover="#684b9b"
            borderHover="0.791px solid #E3D4FE"
            boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            width={isMobileView ? "90%" : "381px"}
            height="46px"
            onClick={navigateToSignup}
          />
        </div>
      </div>
      {isAuthenticated && (
        <div className={styles["upcoming-trips"]}>
          {trips?.pnrDetails !== undefined && trips.pnrDetails.length > 0 && (
            <div className={styles.upcomingHeadingContainer}>
              <span className={styles.upcomingHeading}>
                {t("label_upcomingtrip_header")}
              </span>
              <span className={styles.upcomingSeeAll} onClick={handleSeeAll}>
                <span className={styles.upcomingHeading}>
                  {t("label_seealltrips")}
                </span>
                <TGIcon fillColor="" icon="mini-profile-right-arrow" />
              </span>
            </div>
          )}
          {Object.keys(trips).length > 0 &&
            trips?.pnrDetails !== undefined &&
            trips.pnrDetails.length > 0 && (
              <TGMultiCard
                flightData={recentFlightDetails}
                page="MP"
                isMiniProfile={true}
              />
            )}
        </div>
      )}
      <div id="button-frame" className={styles["mini-profile-action-frame"]}>
        <div className={styles["mini-profile-action-container"]}>
          <div className={styles["my-profile"]} onClick={handleProfile}>
            <TGIcon fillColor={""} icon={"mini-profile-icon"} />
            <div className={styles["profile-text"]}>{t("label_myprofile")}</div>
          </div>
          <TGIcon fillColor="" icon="mini-profile-divider" />
          <div className={styles["logout"]} onClick={handleLogout}>
            <TGIcon fillColor={""} icon={"mini-profile-logout"} />
            <div className={styles["logout-text"]}>{t("label_logout")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRMMiniProfile;
