import React, { useState } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import TGModal from "../../../tg-modal";
import TGButtonVariants from "../../../tg-button-variants";
import TGIcon from "../../../tg-icon";

import styles from "./frequent-otp.module.scss";
import useScreenSize from "../../../../utils/hook/useScreenSize";

interface IProps {
  hidePanel: ()=>void;
  isSessionExpired?: boolean;
  setIsSessionExpired?: any;
}

const FrequentOTP = (props: IProps) => {
  const [show, setShow] = useState(true);
  const { i18n } = useTranslation();
  const handleOpen = () => setShow(true);
  const handleClose = () => {setShow(false); props.hidePanel();props.setIsSessionExpired && props.setIsSessionExpired(false);};
  const isMobile =  useScreenSize()?.deviceSize?.width <768;
  const handleNavigation = () => {
    handleClose();
    props.setIsSessionExpired && props.setIsSessionExpired(false);
    props.hidePanel();
  };

  return (
    <>
      <TGModal
        show={show}
        handleClose={handleClose}
        buttonLabel="Ok"
        customModalClass={styles.customModalClass}
        customOffCanvasClass={styles.customOffCanvasClass}
        centered={true}
      >
        <div className={styles.tcModalFlex}>
          <div className={styles.tcModal}>
            <TGIcon fillColor={"none"} icon={"too-many-otp-icon"} />
            <div className={styles.modalContent}>
              <span className={styles.modalHeading}>
                {props.isSessionExpired ? t("label_session_expired") : t("label_too_many_codes")}
              </span>
              <div className={styles.modalDescription}>
              {props.isSessionExpired ? t("label_session_expired_desc") : t("label_too_many_codes_description")}
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <TGButtonVariants
              label={t("button_too_many_codes_ok")}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
              bgColor="#381B6B"
              bgColorHover="#3D2D5E"
              padding="8px 24px"
              textColor="#FFFFFF"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              height="61px"
              onClick={handleNavigation}
              width={isMobile ? "100%" : "150px"}
            />
          </div>
        </div>
      </TGModal>
    </>
  );
};

export default FrequentOTP;