import React, { useEffect } from "react";
import TGIcon from "../../../tg-icon";
import { constant } from "../../../../config/constant";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";
import styles from "./social-signin.module.scss";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCrmSocialMediaValidationPending } from "../../../../OSCI/src/slice/crmLoginSlice";
import { RootState } from "../../../../OSCI/src/slice/RootReducer";
import { setError } from "../../../../OSCI/src/slice/errorModalSlice";
import { Spinner } from "react-bootstrap";

interface Props {
  showModal: (value: boolean) => void;
}

const SocialSignIn: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    crmSocialLoginValidationDetails,
    crmSocialLoginValidationErrors,
    crmSocialLoginValidationLoading,
    crmSocialLoginType,
  } = useSelector((state: RootState) => state?.osciReducer?.crmLoginData);

  const { clientIdGoogle, appIdFbCRM } = constant;

  let errorDialog = {
    heading: "",
    message: "",
    buttonText: "",
  };

  useEffect(() => {
    //Checking if the API success key and also the object also has value
    if (
      Object.keys(crmSocialLoginValidationDetails).length > 0 &&
      crmSocialLoginValidationDetails?.success
    ) {
      //Checking for the case of new user or not
      if (crmSocialLoginValidationDetails.newUser) {
        if (props.showModal) props.showModal(false);
        navigate(`/${i18n.language}/signup`, {
          state: {
            signUpType: "CRM",
            data: crmSocialLoginValidationDetails.socialMediaData,
            type: crmSocialLoginValidationDetails.socialMediaType,
          },
        });
      } else {
        if (props.showModal) props.showModal(false);
      }
    } else {
      //error handling
    }
  }, [crmSocialLoginValidationDetails, navigate, props, i18n.language]);

  return (
    <>
      <LoginSocialGoogle
        client_id={clientIdGoogle}
        scope="https://www.googleapis.com/auth/userinfo.email"
        className={styles["social-button-wrapper"]}
        access_type="online"
        onResolve={({ provider, data }: IResolveParams) => {
          console.log("google login response", data);
          let payloadData = {
            accessToken: data?.access_token,
            socialMediaType: "google",
            socialMediaData: data,
          };
          dispatch(getCrmSocialMediaValidationPending(payloadData));
        }}
        onReject={(err) => {
          errorDialog = {
            heading: t("error_social_signin_title"),
            message: t("error_social_signin_failure"),
            buttonText: t("button_too_many_codes_ok"),
          };

          if (props.showModal) props.showModal(false);
          // //Modal for cannot login via social media platform
          dispatch(setError(errorDialog));
          console.log("google login error", err);
        }}
      >
        <div
          className={`${styles["signin-social-media"]} ${
            crmSocialLoginValidationLoading &&
            crmSocialLoginType === "google" &&
            styles["button-background"]
          }`}
        >
          <div className={styles["button-content"]}>
            <TGIcon fillColor={""} icon={"google-icon"} />
            {crmSocialLoginValidationLoading &&
            crmSocialLoginType === "google" ? (
              <>
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
              </>
            ) : (
              <div className={styles["sign-in-text"]}>
                {t("button_signin_with_google")}
              </div>
            )}
          </div>
        </div>
      </LoginSocialGoogle>

      <LoginSocialFacebook
        appId={appIdFbCRM}
        className={styles["social-button-wrapper"]}
        fieldsProfile={"id,first_name,last_name,middle_name,name,email"}
        onResolve={({ provider, data }: IResolveParams) => {
          console.log("facebook login response", data);
          let payloadData = {
            accessToken: data?.accessToken,
            socialMediaType: "facebook",
            socialMediaData: data,
          };
          dispatch(getCrmSocialMediaValidationPending(payloadData));
        }}
        onReject={(err) => {
          // Modal for cannot login via social media platform
          errorDialog = {
            heading: t("error_social_signin_title"),
            message: t("error_social_signin_failure"),
            buttonText: t("button_too_many_codes_ok"),
          };
          if (props.showModal) props.showModal(false);
          dispatch(setError(errorDialog));
          console.log("facebook login error", err);
        }}
      >
        <div
          className={`${styles["signin-social-media"]} ${
            crmSocialLoginValidationLoading &&
            crmSocialLoginType === "facebook" &&
            styles["button-background"]
          }`}
        >
          <div className={styles["button-content"]}>
            <TGIcon fillColor={""} icon={"facebook-icon"} />
            {crmSocialLoginValidationLoading &&
            crmSocialLoginType === "facebook" ? (
              <>
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{ width: "10px", height: "10px", margin: "5px" }}
                />
              </>
            ) : (
              <div className={styles["sign-in-text"]}>
                {t("button_signin_with_facebook")}
              </div>
            )}
          </div>
        </div>
      </LoginSocialFacebook>
    </>
  );
};
export default SocialSignIn;
