import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import isAuthenticate from "../../../utils/hook/isAuthenticate";
import {
  getTripDataFailure,
  getTripDataSuccess,
} from "../../../OSCI/src/slice/tripSlice";
import { config } from "../../../config/global";
import styles from "./rop-mini-profile.module.scss";
import { fetchProfileRequest } from "../../../OSCI/src/slice/fetchProfileSlice";
import { RootState } from "../../../OSCI/src/slice/RootReducer";
import TGButtonVariants from "../../tg-button-variants";
import TGIcon from "../../tg-icon";
import TGMultiCard from "../../tg-multi-card";
import { useLocation, useNavigate } from "react-router-dom";
import { FunctionContext } from "../../../OSCI/src/component/header";
import { logoutRequest } from "../../../OSCI/src/slice/logOutSlice";
import TGMainLoader from "../../../shared/tg-main-loader";
import useScreenSize from "../../../utils/hook/useScreenSize";
import { tripDetails } from "../../../OSCI/src/models/models";
import FlightStatusUpcomingTrips from "../../../OSCI/src/component/booking-widget/flight-status/flight-status-upcoming-trips";

const ROPMiniProfile = () => {
  const [privilegeDetails, setPrivilegeDetails] = useState({
    imageStyle: "member",
    tierName: "Basic",
  });
  const profileDetails = useSelector(
    (state) => state?.osciReducer?.profile?.profileData
  );
  useEffect(() => {
    document.documentElement.style.setProperty("--body-overflow", "hidden");
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, []);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = isAuthenticate();
  const navigate = useNavigate();
  const location = useLocation();

  const UPCOMING_TRIPS_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;
  const profileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );

  const trips: tripDetails = useSelector(
    (state: RootState) => state?.osciReducer?.trips?.upcomingTrips
  );

  const recentFlightDetails = {
    pnrDetails: [
      {
        flightDetails:
          Object.keys(trips).length > 0
            ? trips?.pnrDetails[0]?.flightDetails
            : [],
        lastName:
          Object.keys(trips).length > 0 ? trips?.pnrDetails[0]?.lastName : "",
        pnr: Object.keys(trips).length > 0 ? trips?.pnrDetails[0]?.pnr : "",
        tripType:
          Object.keys(trips).length > 0 ? trips?.pnrDetails[0]?.tripType : "",
        segmentGroups:
          Object.keys(trips).length > 0
            ? trips?.pnrDetails[0]?.segmentGroups
            : "",
      },
    ],
  };

  const isMobileView: boolean = useScreenSize().deviceSize?.width < 1081;
  const [profileName, setProfileName] = useState<any>();
  const memberId = sessionStorage.getItem("memberId");

  useEffect(() => {
    switch (sessionStorage.getItem("privilegeCard")) {
      case "MEMBER":
        setPrivilegeDetails({
          imageStyle: "member",
          tierName: "Basic",
        });
        break;
      case "SILVER":
        setPrivilegeDetails({
          imageStyle: "silver",
          tierName: "Silver",
        });
        break;
      case "GOLD":
        setPrivilegeDetails({
          imageStyle: "gold",
          tierName: "Gold",
        });
        break;
      case "PLATINUM":
        setPrivilegeDetails({
          imageStyle: "platinum",
          tierName: "Platinum",
        });
        break;
      default:
        setPrivilegeDetails({
          imageStyle: "member",
          tierName: "Basic",
        });
        break;
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: UPCOMING_TRIPS_API_URL,
      isTeamsite: false,
      successAction: getTripDataSuccess,
      errorAction: getTripDataFailure,
    });
    getProfileData();
    dispatch(fetchProfileRequest());
  }, [dispatch]);

  useEffect(() => {
    edgeHandle();
  }, []);

  function NoupcomingTrips() {
    return (
      <div
        className={
          isMobileView ? styles.imageContainerMobile : styles.imageContainer
        }
      >
        <img src="/assets/noUpcomingTrips.svg" alt="No Upcoming Trips" />
        <p className={styles.imgText}>{t("label_no_upcoming_trips")}</p>
      </div>
    );
  }

  function getProfileData() {
    if (isMobileView) {
      const profileUserName = sessionStorage.getItem("FullName");
      if (profileUserName || profileResponse?.firstName) {
        var getName = profileUserName || profileResponse?.firstName;
        let name = getName.split(" ");
        let displayName = name[1] ? name[1] : name[0];
        sessionStorage.setItem(
          "profileName",
          displayName?.charAt(0).toUpperCase() +
            displayName?.slice(1).toLowerCase()
        );
        var profileName = sessionStorage.getItem("FullName");
        setProfileName(profileName);
      }
    } else {
      var profileName = sessionStorage.getItem("FullName");
      setProfileName(profileName);
    }
  }

  const handleMileageStatement = () => {
    navigate(`/${i18n.language}/myprofile/mileage-statement`);
  };

  const handleSeeAll = () => {
    console.log("To Be Implemented!");
  };

  const handleProfile = () => {
    window.open(`/${i18n.language}/myprofile`, "_blank");
    // navigate(`/${i18n.language}/myprofile`);
  };

  const closeROPMiniProfile = useContext(FunctionContext);

  const handleLogout = () => {
    dispatch(logoutRequest());
    document.documentElement.style.setProperty("--loading-spinner", "block");
  };

  const edgeHandle = () => {
    if (
      window.navigator.userAgent.indexOf("Edg") > -1 &&
      window.innerWidth < 480
    ) {
      document
        .getElementById("rop-mini")
        ?.setAttribute("style", "height: 85vh");
      document
        .getElementById("button-frame")
        ?.setAttribute("style", "top: calc(100vh - 180px)");
    } else {
      var isSafari =
        /Safari/.test(window.navigator.userAgent) &&
        /Apple Computer/.test(window.navigator.vendor);
      if (isSafari) {
        document
          .getElementById("button-frame")
          ?.setAttribute("style", "top: calc(100vh - 70px)");
      }
    }
  };

  return (
    <div id="rop-mini" className={styles["mini-profile-container"]}>
      <div
        className={`${styles["mini-profile-content-frame"]} ${
          styles[privilegeDetails.imageStyle]
        }`}
      >
        <div
          className={styles["close-icon-container"]}
          onClick={closeROPMiniProfile}
        >
          <TGIcon icon="mini-profile-close" fillColor="#F9F9FC" />
        </div>
        <div className={styles["profile-container"]}>
          <div className={styles["profile-name-text"]}>{profileName}</div>
        </div>
        <div className={styles["profile-details"]}>
          <div className={styles["rop-id"]}>{memberId}</div>
          <div className={styles["divider"]}>|</div>
          <div className={styles["tier"]}>{privilegeDetails.tierName}</div>
        </div>
      </div>
      <div className={styles["miles-container"]}>
        <div className={styles["miles-content"]}>
          <div className={styles["rop-logo"]}></div>
          <div className={styles["miles-count-container"]}>
            <div className={styles["miles-count"]}>
              {profileResponse.remainingMiles}
            </div>
            <div className={styles["miles-text"]}>
              {t("label_redemption_header_miles")}
            </div>
          </div>
        </div>

        <TGButtonVariants
          label={t("label_viewmileagestatement")}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="30px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColor="#F5EFFF"
          padding="10px 20px"
          textColor="#684B9B"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="160%"
          borderDisabled="0.791px solid #E3D4FE"
          bgColorDisabled="#E3D4FE"
          boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColorHover="#a196b3"
          textColorHover="#F5EFFF"
          borderHover="0.791px solid #E3D4FE"
          boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          width="381px"
          height="42px"
          onClick={handleMileageStatement}
        />
      </div>
      {isAuthenticated && (
        <div className={styles["upcoming-trips"]}>
          {trips?.pnrDetails !== undefined && trips.pnrDetails.length > 0 && (
            <div className={styles.upcomingHeadingContainer}>
              <span className={styles.upcomingHeading}>
                {t("label_upcomingtrip_header")}
              </span>
              <span className={styles.upcomingSeeAll}>
                <span
                  className={styles.upcomingHeadingTrips}
                  onClick={handleSeeAll}
                >
                  {t("label_seealltrips")}
                </span>
                <TGIcon fillColor="" icon="mini-profile-right-arrow" />
              </span>
            </div>
          )}
          {Object.keys(trips).length > 0 &&
            trips?.pnrDetails !== undefined &&
            trips.pnrDetails.length > 0 && (
              <FlightStatusUpcomingTrips isMiniProfile={true} page="MP" />
            )}
        </div>
      )}
      <div id="button-frame" className={styles["mini-profile-action-frame"]}>
        <div className={styles["mini-profile-action-container"]}>
          <div className={styles["my-profile"]} onClick={handleProfile}>
            <TGIcon fillColor={""} icon={"mini-profile-icon"} />
            <div className={styles["profile-text"]}>{t("label_myprofile")}</div>
          </div>
          <TGIcon fillColor="" icon="mini-profile-divider" />
          <div className={styles["logout"]} onClick={handleLogout}>
            <TGIcon fillColor={""} icon={"mini-profile-logout"} />
            <div className={styles["logout-text"]}>{t("label_logout")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROPMiniProfile;
