import React, { useContext, useEffect, useState } from "react";
import styles from "./mini-profile.module.scss";
import CRMMiniProfile from "./crm-mini-profile";
import ROPMiniProfile from "./rop-mini-profile";
import { FunctionContext } from "../../OSCI/src/component/header";
import { FunctionContextRedemption } from "../../OSCI/src/component/redemption/redemption-header";
import useScreenSize from "../../utils/hook/useScreenSize";

const MiniProfile = () => {
  const [profileType, setProfileType] = useState("");
  const closeROPMiniProfile = useContext(
    !window?.location?.pathname?.includes("redemption") ? FunctionContext : FunctionContextRedemption
  );
  const isMobileView: boolean = useScreenSize().deviceSize?.width < 1081;

  useEffect(() => {
    const sessionProfile = sessionStorage.getItem("isROPLoggedIn");
    if (sessionProfile === "true") {
      setProfileType("ROP");
    }
    if (sessionProfile === "false") {
      setProfileType("CRM");
    }
  }, [sessionStorage.getItem("isROPLoggedIn")]);

  return (
    <div
      className={`${styles.miniProfileWrapper} ${isMobileView ? "" :"global-content-padding"} mini-profile`}
      onClick={closeROPMiniProfile}
    >
      {profileType === "ROP" && <ROPMiniProfile />}
      {profileType === "CRM" && <CRMMiniProfile />}
    </div>
  );
};

export default MiniProfile;
